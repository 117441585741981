import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { useRouter } from "@core/utils/utils";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useMeasuresList() {
  // Use toast
  const toast = useToast();

  const refMeasuresListTable = ref(null);

  // filter options
  const measuresStatusOptions = [
    { label: "Active", key: true },
    { label: "Inactive", key: false },
  ];

  // Table Handlers
  const tableColumns = [
    { label: "Nº", key: "index", sortable: true },
    { label: "libellé", key: "name", sortable: true },
    { label: "description", key: "description" },
    { label: "Statut", key: "is_active", sortable: true },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("created_at");
  const isSortDirDesc = ref(true);
  const measureStatusFilter = ref(null);

  const measuresList = computed(() => {
    return store.getters["measure/all"];
  });

  const totalMeasures = computed(() => {
    const meta = store.getters["measure/meta"];
    return meta?.total ?? 0;
  });

  const dataMeta = computed(() => {
    const localItemsCount = refMeasuresListTable.value
      ? refMeasuresListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMeasures.value,
    };
  });

  const refetchData = () => {
    refMeasuresListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, measureStatusFilter], () => {
    refetchData();
  });

  const fetchMeasuresList = (ctx, callback) => {
    store
      .dispatch("measure/fetchMeasuresList", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        is_active: measureStatusFilter.value,
      })
      .then(() => {
        callback(measuresList.value);
      });
  };

  const deleteMeasure = async (measureID) => {
    const { data, status } = await store.dispatch("measure/destroyMeasure", {
      id: measureID,
    });
    if (status != 200) {
      toast({
        component: ToastificationContent,
        props: {
          title: data.error,
          icon: "AlertTriangleIcon",
          variant: "error",
        },
      });
    } else {
      toast({
        component: ToastificationContent,
        props: {
          title: "La mesure a été supprimée avec succès.",
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });
      refetchData();
    }
  };

  return {
    fetchMeasuresList,
    tableColumns,
    perPage,
    currentPage,
    totalMeasures,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMeasuresListTable,
    refetchData,
    deleteMeasure,

    measureStatusFilter,
    measuresStatusOptions,
  };
}
